import { ErrorPageView } from '../views/ErrorPageView';
import { FormattedMessage } from 'react-intl';
import React from 'react';

const FourZeroFour = () => {
  return (
    <ErrorPageView
      title={
        <FormattedMessage
          defaultMessage={'This page does not exist'}
          description={'Not found page, title'}
        />
      }
      description={
        <FormattedMessage
          defaultMessage={
            'Please check your address.<br></br><br></br> If you need help with Guidin, please contact placeholder@guidin.fi'
          }
        />
      }
    />
  );
};
FourZeroFour.displayName = '404Page';
export default FourZeroFour;
